import { joinURL } from "ufo"
import type { ProviderGetImage } from "@nuxt/image"
import { createOperationsGenerator } from "#image"

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    width: "width",
    height: "height",
    quality: "quality",
    format: "format",
    resize: "resize",
  },
  valueMap: {
    format: {
      png: "png",
      jpeg: "jpg",
      webp: "webp",
      avif: "avif",
      gif: "gif",
      ico: "ico",
      svg: "svg",
      heic: "heic",
      bmp: "bmp",
      tiff: "tiff",
      pdf: "pdf",
      mp4: "mp4",
    },
  },
  joinWith: "&",
  formatter: (key, val) => `${key}=${val}`,
})

export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = '' } = {}
) => {
  if (modifiers.format) {
    modifiers.img_format = modifiers.format
    delete modifiers.format
  }
  const params = operationsGenerator(modifiers as any)

  const url = params
    ? `${joinURL(baseURL, src)}?${params}`
    : joinURL(baseURL, src)
  return {
    url,
  }
}
